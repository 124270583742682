import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from './images/logo.png';
import './Navbar.css';
import mobileLogo from './images/logo-mobile.png';

function Navbar() {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const closeMenu = () => setClick(false);
    const [sticky, setSticky] = useState(false);
    const handleScroll = () => {
        if (window.scrollY > 0) {
            setSticky(true);
        } else {
            setSticky(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <header>
            <div className={`navbar-container ${sticky ? 'sticky' : ''}`}>
                <div className="logo-container">
                    <Link to='/' className="navbar-logo" onClick={closeMenu}>
                        <img id='logo-icon' src={sticky && window.innerWidth <= 960 ? mobileLogo : logo} alt="Logo" />
                    </Link>
                </div>
                <div className="menu-container">
                    <div className='menu-icon' onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>
                    <ul className={click ? "nav-menu active" : "nav-menu"}>
                        <li className='nav-item'>
                            <Link to='/' className='nav-links' onClick={closeMenu}>Home</Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/inventory' className='nav-links' onClick={closeMenu}>Inventory</Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/requestCar' className='nav-links' onClick={closeMenu}>Car Finder</Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/about' className='nav-links' onClick={closeMenu}>About Us</Link>
                        </li>
                        <li className='nav-item'>
                            <Link to='/contact' className='nav-links' onClick={closeMenu}>Contact Us</Link>
                        </li>
                        
                    </ul>
                </div>
            </div>
        </header>
    );
}

export default Navbar;
